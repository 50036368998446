.image-con {
  position: relative;
  height: 280px;
  width: 451px;
  /* background-color: red; */
}
.image-con img {
  height: 100%;
  width: 100%;
  /* object-fit: cover;/ */
}


.about_section_main{
  margin-top: 10px;
  
  }

.about-min-navbar{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  width: 100%;
  height: 50px;
  margin-top: 80px;
}

.about-min-navbar ul{
  display: flex;
  justify-content:"center";
  align-items: "center";
}

.about-min-navbar ul li{
  margin-top: 0px;
  margin-left: 10px;

}
.movement-min-navbar{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  width: 100%;
  height: 50px;
  margin-top: 80px;
}

.movement-min-navbar ul{
  display: flex;
  justify-content:"center";
  align-items: "center";
  margin-top: 12px;
}

.movement-min-navbar ul li{
  margin-top: 0px;
  margin-left: 15px;

}

.movement-min-navbar-mobile{
  width: 100%;
  background-color: #3498db;
  overflow: hidden;
  box-sizing: border-box;
  display: none;

}

.movement-min-navbar-mobile ul{
  width: 100%;
  
}

.movement-min-navbar-mobile ul li{
 width: 100%;
 height: 50px;
}




.about_section_main a,
.about_section_main a:hover,
.about_section_main a:focus,
.about_section_main a:active {
  text-decoration: none;
  outline: none;
}

.about_section_main a,
.about_section_main a:active,
.about_section_main a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

.about_section_main ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.about_section_main img {
  max-width: 100%;
  height: auto;
}
.about_section_main section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}

.about_section_main .sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.about_section_main .sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #ffa500;
  font-weight: 500;
  margin-bottom: 15px;
}

.about_section_main .sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.28em;
  color: #222222;
  font-weight: 600;
  padding-bottom: 18px;
}

.about_section_main .sec-title h2:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.about_section_main .sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  margin-top: 35px;
}

.about_section_main .sec-title.light h2 {
  color: #ffffff;
}

.about_section_main .sec-title.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}

.about_section_main .list-style-one {
  position: relative;
}

.about_section_main .list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 12px;
}

.about_section_main .list-style-one li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0px;
  display: block;
  font-size: 18px;
  padding: 0px;
  color: #ff2222;
  font-weight: 600;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.6;
  font-family: "Font Awesome 5 Free";
}

.about_section_main .list-style-one li a:hover {
  color: #44bce2;
}

.about_section_main .btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #ffa500;
}

.about_section_main .btn-style-one:hover {
  background-color: #e49400;
  color: #ffffff;
}
.about_section_main .about-section {
  position: relative;
  padding: 120px 0 70px;
}

.about_section_main .about-section .sec-title {
  margin-bottom: 45px;
}

.about_section_main .about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about_section_main
  .about_section_main
  .about-section
  .content-column
  .inner-column {
  position: relative;
  padding-left: 30px;
}

.about_section_main .about-section .text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
}

.about_section_main .about-section .list-style-one {
  margin-bottom: 45px;
}

.about_section_main .about-section .btn-box {
  position: relative;
}

.about_section_main .about-section .btn-box a {
  padding: 15px 50px;
}

.about_section_main .about-section .image-column {
  position: relative;
}

.about_section_main .about_secon_main .about-section .image-column .text-layer {
  position: absolute;
  right: -110px;
  top: 50%;
  font-size: 325px;
  line-height: 1em;
  color: #ffffff;
  margin-top: -175px;
  font-weight: 500;
}

.about_section_main .about-section .image-column .inner-column {
  position: relative;
  padding-left: 50px;
  padding-bottom: 0px;
}
.about_section_main .about-section .image-column .inner-column .author-desc {
  /* position: absolute; */
  /* bottom: 16px; */
  z-index: 1;
  background: orange;
  padding: 10px 15px;
  /* left: 96px; */
  width: calc(100% - 152px);
  /* border-radius: 50px; */
}
.about_section_main .about-section .image-column .inner-column .author-desc h2 {
  font-size: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 0;
}
.about_section_main
  .about-section
  .image-column
  .inner-column
  .author-desc
  span {
  font-size: 16px;
  letter-spacing: 6px;
  text-align: center;
  color: #fff;
  display: block;
  font-weight: 400;
}
/* .about_section_main .about-section .image-column .inner-column:before {
  content: "";
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 160px);
  top: -80px;
  left: -3px;
  background: transparent;
  z-index: 0;
  border: 44px solid #ffdc5d;
} */

.about_section_main .about-section .image-column .image-1 {
  position: relative;
}
.about_section_main .about-section .image-column .image-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about_section_main .about-section .image-column .image-2 img,
.about_section_main .about-section .image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  border-radius: 46px;
}

.about_section_main .about-section .image-column .video-link {
  position: absolute;
  left: 70px;
  top: 170px;
}

.about_section_main .about-section .image-column .video-link .link {
  position: relative;
  display: block;
  font-size: 22px;
  color: #191e34;
  font-weight: 400;
  text-align: center;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about_section_main .about-section .image-column .video-link .link:hover {
  background-color: #191e34;
  color: #ffffff;
}

/* ====================== STARTER CODE ====================== */
.starter {
  height: 100vh;
  width: 100%;
  /* overflow: hidden; */
  background-color: white;
  position: relative;
  z-index: 2;

  .bg_img img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .logo {
    height: 100px;
  }

  .left_img,
  .right_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .left_content,
  .right_content {
    background: #fca607;
    width: fit-content;
    padding: 5px 30px;
    border-radius: 100px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    color: white;

    h6 {
      text-transform: uppercase;
    }
  }
  .right_content {
    background: #ff6135;
  }
  .left_btn {
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(0%, -50%);
    height: 70px;
    cursor: pointer;
  }
  .right_btn {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(0%, -50%);
    height: 70px;
    cursor: pointer;
  }
  .imgpart_middle {
    height: fit-content;
    .laptop_man {
      width: 70%;
      object-fit: contain;
      margin-top: 1vmax;
    }
    .rounded_bar {
      height: 60px;
      width: 100%;
      background: rgb(211, 211, 211);
      border-radius: 100px;
      box-shadow: 3px 5px 0px black;
      position: relative;

      img {
        position: absolute;
        height: 100%;
        width: 70%;
        object-fit: cover;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -30%);
      }
    }
  }
}

@media (max-width: 768px) {
  .starter {
    height: auto;

    .bg_img img {
      height: 100%;
      width: 100%;
    }
    .left_img,
    .right_img {
      height: 70vh;
      width: 70vw;
      display: block;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 720px){
  .about_section_main .about-min-navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3498db;
    width: 100%;
    height: 50px;
    margin-top: -30px;
  }
  .about_section_main .about-min-navbar ul li{
    font-size: 10px;
  }

}

@media only screen and (max-width: 500px){
  .about-min-navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3498db;
    width: 100%;
    height: 50px;
    margin-top: -30px;
  }
   .about-min-navbar ul li{
    font-size: 10px;
  }

  .movement-min-navbar{
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #3498db;
    width: 100%;
    height: 50px;
    margin-top: 80px;
  }
  

  .movement-min-navbar-mobile{
    width: 100%;
    background-color: #3498db;
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    margin-top: -20px;
  
  }
  
  .movement-min-navbar-mobile ul{
    width: 100%;
    
  }
  
  .movement-min-navbar-mobile ul li{
   width: 100%;
   height: 50px;
  }



}

