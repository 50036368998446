.custom_nav {
  /* background: linear-gradient(to right, #fe935175, #ff5e4977) !important;
  padding: 1vmax 3vmax !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 100 !important; */

  z-index: 100 !important;
  width: 100% !important;
  top: 0 !important;
 display:none;
 background-color: #ffb202;

  
}

.custom_nav a {
  font-weight: 600 !important;
}

.dropDown {
  font-weight: 600 !important;
}

.navItem{
  position: relative;
  width: 100px;
  text-align: center;
  letter-spacing: 0.5px;

}

.navItem1{
  position: relative;
  letter-spacing: 0.5px;

}

.nav_color{
  background-color: transparent;
}


.navItem::before{
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  left: 0px;
  right: 0px;
  background-color:#ffb202;
  bottom: -2px;
  transition: width 0.5s;  
  margin: auto;
  }

  .navItem:hover::before{
    width: 100px;
    height: 2px;
    margin: auto;
    }

    .navItem1::before{
      content: "";
      position: absolute;
      width: 0px;
      height: 2px;
     
      background-color:#ffb202;
      bottom: -2px;
      transition: width 0.5s;  
      margin: auto;
      }
    
      .navItem1:hover::before{
        width: 100px;
        height: 2px;
        margin: auto;
        }

.home_btns{
  background: #fff !important;
  color: #000;
  font-family: "Poppins", serif;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-size: 13px;

  transition: all;
}

.home_btns:hover{
  background: #ffb202 !important;
  color: #fff;

}

.mobile_navbar{
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
}



/* Main content */
.main-content {
  margin-left: 0;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.main-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }


}



@media screen and (max-width: 768px) {
  .home_btns{
    margin-top: 10px;
  }

  .custom_nav{
    display: none;
  }
}