
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.complaintText {
  font-family: poppins;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background: rgb(254, 154, 82);
  background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
  padding: 15px;
  border-radius: 10px;
}

.complaintText2 {
  font-family: poppins;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  width: 100%;
  background: #441DC0;
  padding: 15px;
  margin-bottom: 0px;
  color: #fff;
  background: rgb(254, 154, 82);
  background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
}

.complaintSubmitBtn {
  border-color: #fe9a52;
  background: rgb(254, 154, 82);
  background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
}

.complaintSubmitBtn:hover {
  border-color: #fe9a52;
  background: rgb(254, 154, 82);
  background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
}

.complaintTipBox {
  background: rgb(254, 154, 82);
  background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
}