/* marquee div container */
.marquee-l-container {
    font-size: 1.5vw;
    color: red;
    font-family: 'Courier New', Courier, monospace;
    height: 2.5vw;
    overflow: hidden;
    /* background-color: #c9cf9bd4; */
    position: relative;
    margin-top: 6rem;
}

/* nested div inside the container */
.marquee-l-container div {
    display: block;
    width: 200%;
    position: absolute;
    overflow: hidden;
    animation: marquee 12s linear infinite;
}

/* span with text */
.marquee-l-container span {
    float: left;
    width: 50%;
}

/* keyframe */
@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

@keyframes marquee-mobile {
    0% {
        left: 0;
    }

    100% {
        left: -200%;
    }
}

@media (max-width: 768px) {
    .marquee-l-container {
        font-size: 3.5vw;
        height: 6vw;
    }
    .marquee-l-container div {
        width: 400%;
        animation: marquee-mobile 14s linear infinite;
    }
    .marquee-l-container span {
        width: 100%;
    }
}