.carousel-indicators {
    position: absolute;
    justify-content: center;
    margin: 2%;
}
.carousel-indicators .active{
    background-color: rgb(255, 85, 0);
    
}

.carousel-indicators [data-bs-target]{
    background-color: rgb(223, 110, 82);

}
.carousel-control-prev {
    left: 0;
    right: 45px;
    display: none;
}
.carousel-control-prev span{
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 5px 7px 29px 2px rgb(255, 104, 3);
}
.carousel-control-next span{
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 7px 5px 29px 2px rgb(255, 104, 3);
}

.carousel-control-prev-icon{
    background-size: 64% 64%;
    color: #000000;
}
.carousel-control-next-icon{
    background-size: 64% 64%;
    color: #000000;
    display: none;
}