.icon {
    height: auto;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease .4s;
    margin-bottom: 10px;
}
.member{
    display: flex;
    justify-content: center;
    flex-direction: column;
   background: linear-gradient(to right, #FFC801, #ffa202);
    gap: .5vmax;
    cursor: pointer;
    border-radius: 15px;
}

.member h1{
    font-size: 2vmax;
    background-color: #FFC801;
    width: fit-content;
    padding: .5vmax 2vmax;
    border-radius: 10px;

}