.pic{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
}
.pic img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
