#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px;
}

.slide {
    /* background-color: red; */
    background-color: #ffff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0%;
    border-radius: 15px;
}

.swiper-slide img {
    height: 100px;
    margin-bottom: 40px;
}

.swiper-slide h1 {
    font-size: 18px;
}

.swiper-pagination {
    transform: translateY(60%);
}

.sectorWord{
    background: -webkit-linear-gradient(#fe9a52, #ff5748);
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}