.hero_main {
  height: 35vmax !important;
  width: 100% !important;
  overflow: hidden !important;
  /* margin-top: 9rem; */
  margin-top: 1rem;
  background-color: #ffc801 !important;
}
.images {
  height: 100% !important;
  width: 100% !important;
  background-color: #ffc801 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.images img {
  height: 100% !important;
  width: 83% !important;
  object-fit: cover !important;
}

.left_btn,
.right_btn {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  border: 1px solid #aa6c00 !important;
  cursor: pointer;
}
.arrows {
  color: #aa6c00 !important;
}

/* css breakpoints */

@media screen and (max-width: 768px) {
  .hero_main {
    height: 70vmax !important;
  }
  .images img {
    height: 50% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
    .hero_main {
        height: 50vmax !important;
      }
      .images img {
        height: 50% !important;
        width: 100% !important;
      }
}
