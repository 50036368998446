.feed{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: rgb(224, 224, 224);
    justify-content: center;
    /* padding: 4vmax 6vmax; */
    padding-top: 6vmax;
    padding-left: 3vmax;
    padding-right: 3vmax;
}
.personal_info{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.self_image{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
}
.self_image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.personal_info h4{
    font-size: 16px;
    margin-bottom: 6px;
}
.personal_info h5{
    font-size: 13px;
    opacity: .8;
}