  .col1,
  .col2,
  .col3,
  .col4 {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .col1>.line,
  .col2>.line,
  .col3>.line,
  .col4>.line {
    height: 2px;
    width: 70%;
    background-color: rgb(148, 148, 148);
    margin-bottom: 10px;
  }

  .col1>h1,
  .col2>h1,
  .col3>h1,
  .col4>h1 {
    font-size: 2rem;
  }

  .col1>h2,
  .col2>h2,
  .col3>h2,
  .col4>h2 {
    font-size: 1.5rem;
  }

  .col1>h1 span,
  .col2>h1 span,
  .col3>h1 span,
  .col4>h1 span {
    font-weight: bold;
  }

  @media (max-width:980px) {

    .col1>h1,
    .col2>h1,
    .col3>h1,
    .col4>h1 {
      font-size: 1.5rem;

    }

    .col1>h2,
    .col2>h2,
    .col3>h2,
    .col4>h2 {
      font-size: 1rem;
    }
  }

  @media (max-width:430px){
    .col1>h1,
    .col2>h1,
    .col3>h1,
    .col4>h1 {
      font-size: .8rem;
    }

    .col1>h2,
    .col2>h2,
    .col3>h2,
    .col4>h2 {
      font-size: .6rem;
    }
  }