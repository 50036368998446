body {
  background-color: #fff;
}

.youtube-main {
  width: 100%;
  min-height: 40vh;
  margin: 50px 0;
  background-color: red;
}

.left {
  border-radius: 20px !important;
  overflow: hidden;
  min-height: 100%;
}

.right {
  padding-left: 30px;
  position: relative;
}


@media (max-width:770) {
  .right {
    padding-left: 0px;
    background-color: red;

  }
}

.tweet {
  opacity: 0;
  transition: opacity 0.5s;
  right: 0%;
  width: 90%;
  height: 100%;
}

.tweet.visible {
  opacity: 1;
}