main {
  margin-top: 5vh;
  padding: 3vmax 0;
}
.row1 {
  color: white;
  padding-bottom: 4vmax;
  border-bottom: 1px solid gray;
  margin-bottom: 1vmax;
}
.row1 a {
  text-decoration: none;
  color: gray;
  transition: all ease 0.4s;
}
.row1 a:hover {
  color: #ff5d49;
  margin-left: 10px;
}

input {
  outline: none;
  border: none;
  background-color: white;
  padding: 10px 20px;
  margin-top: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.footer_btn {
  padding: 10px 20px;
  outline: none;
  border: none;
  background: linear-gradient(to right, #FFC801, #ffa202);
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.reserved {
  padding-top: 1vmax;
  color: white;
}
.footerLogo {
  position: absolute;
  left: -30px;
}
