

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.main{
    height: 100vh;
    width: 100%;
    background:linear-gradient(#66ACE5,#FFFDFD);
    display:flex;
    justify-content: center;
    align-items: center;
}

.main .box{
position: absolute;
width: 100%;
height: 85vh;
margin-top: 100px;
}

.main .box .parachute {
    position: absolute;
    transition: transform 0.3s ease-out;
  }
  
  .leftParachute {
    left: 10%;
    top: 25%;
    width: 270px;
    height: 270px;
    animation: moveLeft 1.5s ease-out;
  }
  
  .rightParachute {
    right: 10%;
    top: 50%;
    width: 100px;
    height: 100px;
    animation: moveRight 1.5s ease-out;
  }

  /* Clouds */
.cloud {
    position: absolute;
    transition: transform 0.3s ease-out;
  }
  
  .cloud:nth-child(3) {
    width: 220px;
    left: 0%;
    animation: cloudLeft 2s ease-out;
  }
  
  .cloud:nth-child(4) {
    width: 220px;
    right: -5%;
    animation: cloudRight 2s ease-out;
  }

  /* Birds */
.bird {
    position: absolute;
    width: 100px;
    animation: flyLeft 10s linear infinite;
  }
  
  /* Bird 1 - Slightly Higher */
  .bird1 {
    top: 20%;
    left: -120px;
    animation-delay: 0s;
  }
  
  /* Bird 2 - Lower and Slight Delay */
  .bird2 {
    top: 40%;
    left: -120px;
    animation-delay: 3s;
  }

  @keyframes moveLeft {
    from {
      transform: translateX(-200%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes moveRight {
    from {
      transform: translateX(200%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes cloudLeft {
    from {
      transform: translateX(-150%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes cloudRight {
    from {
      transform: translateX(150%);
    }
    to {
      transform: translateX(0);
    }
  }
  

 
  
  /* Fly Left Animation for Birds */
  @keyframes flyLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw);
    }
  }

  .content {
    text-align: center;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .content h1 {
   font-weight: 800;
   font-family: "Poppins", serif;
   color: #000;
   font-size: 4em;
  }

  .content .btn {
   background-color: #fff;
   color: #000;
   border-radius: 10px;
   font-family: "Poppins", serif;
   margin-top: 5px;
   }

   .content .btn {
    background-color: #fff;
    color: #000;
    border-radius: 0px;
    line-height: 20px;
    font-family: "Poppins", serif;
    margin-top: 5px;
    border-radius: 10px;
    letter-spacing: 0.5px;
    }

    .content .btn:hover{
      background-color: #ffb202;
      color: #fff;
    }

    /* .content .btn::before{
      content: "";
      position: absolute;
      width: 170px;
      height: 10px;
      background-color: #fff;
      left: -5px;
      right: 0px;
      margin: auto;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      bottom: -5px;
      transition: width 0.5s, 
      height 0.5s 0.5s;  
      }

      .content .btn::after{
        content: "";
        position: absolute;
        width: 170px;
        height: 5px;
        background-color: #fff;
        left: -5px;
        right: 0px;
        margin: auto;
       
       top: -5px;
        transition: width 0.5s, 
        height 0.5s 0.5s;  
        }

        
      .content .btn:hover::after{
        width: 0;
        height: 5px;
        transition: height 0.5s,
                     width 0.5s 0.5s       ;
            
         }
   

      .content .btn:hover::before{
       width: 0;
       height: 5px;
       transition: height 0.5s,
                    width 0.5s 0.5s       ;
           
        }
   */
 

   .content .btn1 {
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    font-family: "Poppins", serif;
    margin-top: 5px;
    margin-left: 5px;
    line-height: 20px;
    letter-spacing: 0.5px;

    }

    .content .btn1:hover  {
    background-color: #ffb202;
    color: #fff;
    }

    .content .headline{
      font-size: 5em;
    
     }

    @media only screen and (max-width: 600px) {
     .main {
        height: 85vh;

      }

      .main .box{
        position: absolute;
        width: 100%;
        height: 85vh;
        margin-top: 220px;
        }

        .content .headline{
          font-size: 3em;
        
         }

         .content .btn {
          background-color: #fff;
          color: #000;
          border-radius: 0px;
          line-height: 20px;
          font-family: "Poppins", serif;
          margin-top: 5px;
          border-radius: 10px;
          letter-spacing: 0.5px;
          width: 200px;
          }

          
   .content .btn1 {
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    font-family: "Poppins", serif;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 200px;
    margin-top: 20px;

    }

    }