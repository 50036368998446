.loginBtnn {
    background: rgb(254, 154, 82);
    background: linear-gradient(90deg, rgba(254, 154, 82, 1) 0%, rgba(255, 87, 72, 1) 35%);
    border-color: #ff5748;
}
.loginBtnn:hover {
    border-color: #ff5748;
}

.loginContainer {
  background: url("../consumer-right-image.jpg") no-repeat fixed center ;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  
  }

  .login-card{
    width:30%;
    right: 100px;
    top: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
   
  }

  @media only screen and (max-width: 600px) {
    .loginContainer {
        background: url("../consumer-right-image.jpg") no-repeat fixed center ;
        background-size: cover;
        height: 100vh;
        display: flex;
        justify-content:center;
        align-items: center;
        
        }

    .login-card{
        width:95%;
        right: 0px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
       
      }
  }

  @media only screen and (max-width: 800px) {
    .loginContainer {
        background: url("../consumer-right-image.jpg") no-repeat fixed center ;
        background-size: cover;
        height: 100vh;
        display: flex;
        justify-content:center;
        align-items: center;
        
        }

    .login-card{
        width:95%;
        right: 0px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
       
      }
  }